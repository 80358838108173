.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.description {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input {
  background-color: #fbfbfb;
  border-color: #d2d2d2;
  border-radius: 4px;
  width: 300px;
}
