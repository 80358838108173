.view {
  position: relative;
  padding-bottom: 80px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.history {
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid var(--gray);
  padding: 6px 15px;
  display: flex;
  align-items: center;
}

.history span {
  margin-top: 2px;
  margin-left: 10px;
  color: var(--darkGray);
  font-weight: 500;
}

.eye {
  background-color: transparent;
  color: var(--orange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.eye svg {
  width: 22px;
  height: 22px;
}

.warning {
  color: red;
  margin-right: 8px;
  margin-bottom: -2px;
}

.ordersButtons{
  display: flex;
  gap: 1rem
}

@media screen AND (max-width: 720px) {
  .ordersButtons{
    flex-direction: column;
  }
}