.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.infoWrap {
  background-color: white;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  border-radius: 6px;
  margin-bottom: 40px;
  padding: 20px 0;
}

.infoCol {
  padding: 0 20px;
}

.infoCol span {
  display: block;
  margin-bottom: 15px;
}

.infoCol span:last-child {
  margin-bottom: 0;
}

.actions {
  display: flex;
  align-items: center;
}

.switchWrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.eye {
  background-color: transparent;
  color: var(--orange);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.eye svg {
  width: 22px;
  height: 22px;
}
