@font-face {
  font-family: BebasNeue;
  src: url(assets/fonts/bebas/BebasNeue-Book.otf);
  font-weight: 200;
}

@font-face {
  font-family: BebasNeue;
  src: url(assets/fonts/bebas/BebasNeue-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: BebasNeue;
  src: url(assets/fonts/bebas/BebasNeue-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: BebasNeue;
  src: url(assets/fonts/bebas/BebasNeue-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: AkiraExpanded;
  src: url(assets/fonts/akira/Akira-Expanded-Demo.otf);
  font-weight: 400;
}

@font-face {
  font-family: AkiraOutline;
  src: url(assets/fonts/akira/Akira-Outline.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Anton;
  src: url(assets/fonts/anton/Anton-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Compact;
  src: url(assets/fonts/compact/compact.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Compact;
  src: url(assets/fonts/compact/CompactaBT.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Black.ttf);
  font-weight: 800;
}

body {
  margin: 0;
  font-family: Roboto, "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --orange: #ff671d;
  --black: #585858;
  --gray: #aeaea6;
  --darkGray: #585858;
  --offRed: #fef2f4;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #a1a1a1;
  font-weight: 500;
  font-size: 14px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--darkGray);
  font-weight: 700;
}

.ant-tabs-ink-bar {
  height: 4px !important;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #a4a4a4 !important;
}

/* .ant-switch-checked {
  background-color: var(--orange);
} */

.ant-layout-sider-zero-width-trigger {
  background-color: var(--darkGray);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item-control {
  width: 100%;
}

.editor-product .ant-select-selector {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
}

.editor-product .ant-upload.ant-upload-select {
  width: 100%;
}

.ant-checkbox input {
  border-color: #bfbfbf;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

.main-button {
  width: 300px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 14px;
}

@primary-color: #FF671D;@border-radius-base: 4px;