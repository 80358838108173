.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.input {
  background-color: #fbfbfb;
  border-color: #d2d2d2;
  border-radius: 4px;
  margin-right: 20px;
}

.select {
  width: 200px;
}

.search {
  width: 120px;
}
.newQuestion{
  width: 180px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWrap span {
  display: block;
}

.label {
  font-weight: 500;
  color: var(--gray);
}

.title {
  font-weight: 700;
  font-size: 24px;
  font-family: BebasNeue;
}

.bodyRatings {
  margin-top: 40px;
}

.scores {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.scoreWrap {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
}

.score {
  margin-bottom: 10px;
  color: var(--orange);
  font-size: 32px;
}

.scoreLabel {
  color: #404040;
  font-size: 12px;
}

.star {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
