.editor :global(.ant-upload-select-picture) {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.title {
  color: var(--darkGray);
  font-family: BebasNeue;
  font-weight: 700;
  font-size: 20px;
}

.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
}

.input,
.input :global(.ant-select-selector) {
  background-color: #fbfbfb !important;
  border-color: #d2d2d2 !important;
  border-radius: 4px !important;
}

.itemColumn {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.uploadImage {
  border: 1px solid #d2d2d2;
  background-color: #fbfbfb;
  color: #383838;
  font-weight: 500;
  width: 100%;
  height: 41px;
  border-radius: 4px;
}

.center {
  text-align: center;
  display: block;
  margin-top: 5px;
}

.labelSection {
  display: block;
  margin-bottom: 15px;
  margin-top: 40px;
}

.hoursList {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.labelHour {
  width: 80px;
  text-align: left;
}

.searchbox {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 250px;
  height: 40px;
  padding: 0 15px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 15px;
  top: 15px;
}

.mapWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.gps {
  position: absolute;
  height: 34.65px;
  width: 45px;
  margin-top: -17.325px;
  text-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
}

.itemSubmit {
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
}

.submit {
  width: 250px;
  font-weight: 700;
  font-size: 13px;
}

.delete {
  width: 150px;
  margin-right: 20px;
}

.preview {
  background-color: #f3f3f3;
  height: 140px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
}
.formItem{
  display: flex;
  gap:10px;
}

.plusMinusIcon{
  cursor: pointer;
}

.innerHour{
  width: 84%;
}

@media only screen and (max-width:650px) {
  .innerHour{
    width: 88%;
  }
}
