
.infoHead {
    padding: 0.3em 5.3em 0.3em 0.3em;
    margin: 0.2em;
    font-size: 1.1em;
}
.infoContent {
    padding:0.5em;
    
}
.infoContent span {
    width: 6em;
    display: inline-block;
    font-weight: normal;
}
.infoText {
    font-size: 1em;
    padding: 0.2em;
    font-weight: bold;
}